@import 'styles/variables';

.BothyListContainer {
  &.empty {
    .empty-message {
      padding: $double-space-around;
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
}
