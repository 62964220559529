.BothyList {
  display: flex;
  flex-direction: column;

  .ui.one.cards {
    margin-left: -0.5em;
    margin-right: -0.5em;
  }

  .ui.card.BothyCard {
    width: 100%;
  }
}
