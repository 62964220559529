@import 'styles/variables';

.BothyPopup {
  max-width: 250px;
  position: relative;

  .icon.close {
    position: absolute;
    top: -10px;
    right: -10px;
    color: $grey;
  }

  .ui.header:first-child {
    margin: 0;
  }
}
