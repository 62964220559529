@import 'styles/variables';

.AchievementsPage {
  display: flex;
  flex-direction: column;
  min-height: 0;

  .AchievementSection {
    margin-bottom: $double-space-around;
  }

  &.compact {
    .AchievementSection {
      margin-bottom: 0;

      i.icons .icon:first-child {
        margin-right: 0;
      }
    }

    .divider {
      margin: 0;
    }
  }
}
