@import 'styles/variables';

.AnonymousUserActionGuard {
  margin: $double-space-around;
  text-align: center;

  .sign-up-link {
    cursor: pointer;
    color: $primary-color-dark;
  }

  .action {
    opacity: 0.5;
  }
}
