html,
body,
#root,
.Root,
.Authentication {
  margin: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.ui.modal {
  > .close {
    top: 0.5rem;
    right: 0.5rem;
    color: rgba(0, 0, 0, 0.87);
  }

  > .content {
    > .image + .description {
      flex: 1 1 auto;
      padding-left: 1.5rem;
    }
  }
}

.ToastContainer.ui-alerts {
  &.mobile {
    margin: 0;
    left: initial;
  }
}
