@import 'styles/mixins';

.HomePage {
  flex-direction: row;

  .info-pane {
    @include dropshadow;
    position: relative;
    flex: 0 0;
    flex-basis: 45%;
    max-width: 800px;
    height: 100%;
    transition: transform 0.1s ease, flex-basis 0.5s ease, max-width 0.5s ease;

    &.minor {
      flex-basis: 35%;
      max-width: 600px;
    }
  }

  .BothyMap {
    flex: 1 0 40%;
  }
}
