@import 'pages';
@import 'tabs';
@import 'overrides';
@import 'variables';

.ui.divider.small {
  width: 25%;
  margin: 0 auto;
}

.SavedIcon {
  &:hover {
    cursor: pointer;
  }
}

// TODO: Change uses of this to be a button
.link-text {
  cursor: pointer;
  color: $accent-color;

  &:hover {
    text-decoration: underline;
  }

  &.secondary {
    color: $secondary-color;
  }
}

i.icon.clickable {
  cursor: pointer;
}
