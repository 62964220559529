@import 'styles/variables';

.LoginHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: $double-space-around;
}
