@import 'styles/variables';
@import 'styles/mixins';

.BothyDetails {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  .image {
    flex: 0 0 210px;
    object-fit: cover;
    min-height: 0;
    max-height: 210px;
  }

  .floating-button {
    @include dropshadow;
    position: absolute;
    top: $space-around;
    padding: $space-around;
    border-radius: 20%;
    background-color: $background-color;
    cursor: pointer;

    i.icon {
      margin: 0;
    }

    &.save {
      color: $accent-color;
      right: $space-around;
      font-size: 16px;
    }

    &.back {
      left: $space-around;
    }
  }

  > .ui.divider {
    margin: 0;
  }

  .details {
    margin: $double-space-around;
    display: flex;
    flex-direction: column;
    position: relative;

    > .VisitCounter {
      position: absolute;
      top: -2 * $double-space-around;
      right: 0;
    }

    p {
      text-align: justify;
      word-break: break-word;
    }

    .ui.header {
      margin-top: $space-around;
    }
  }

  .location {
    margin: $double-space-around;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    color: $accent-color;
  }

  .add-visit {
    margin-top: $double-space-around;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $accent-color;
    text-transform: uppercase;

    i.icon {
      margin-bottom: $space-around;
    }
  }

  .AddVisitForm {
    padding: $double-space-around;
  }
}
