@import 'styles/variables';
@import 'styles/mixins';

.BothyCard {
  > .details {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include above(small) {
      flex-direction: column;
    }
  }

  .content {
    padding: $double-space-around;
    flex: 1 1 auto;

    .description {
      margin-top: $space-around;
      min-height: 40px;
    }

    &:not(.extra) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .extra.content {
    text-align: right;
    padding: $double-space-around;
    flex: 1 0 auto;

    .BothyInfoBar {
      height: 100%;
    }
  }

  .header {
    font-size: 1.4em !important;
    margin-bottom: $space-around;
  }

  .image > img {
    width: 100%;
    height: 140px !important;
    object-fit: cover;
  }

  .LazyImage {
    > div {
      height: 100%;
      width: 100%;

      > .placeholder.image {
        width: 100%;
        height: 140px;
      }
    }
  }

  @include above(small) {
    flex-direction: row !important;

    .image {
      flex: 0 0 30% !important;

      > img {
        height: 100% !important;
        object-fit: cover;
      }
    }

    .LazyImage {
      flex: 0 0 30% !important;

      > div {
        > .placeholder.image {
          height: 100%;
        }
      }
    }

    .extra.content {
      padding: $space-around;
      border-top: 1px solid $border-color;
    }
  }

  &.Placeholder {
    .header {
      margin-bottom: 0;
    }

    .image {
      width: 100%;
      height: 140px !important;
      object-fit: cover;
    }

    @include above(small) {
      .image {
        flex: 0 0 30% !important;
        height: 100% !important;
      }
    }
  }
}
