@import 'styles/variables';
@import 'styles/mixins';

.AchievementSection {
  .ui.header {
    margin: $double-space-around;
  }

  // TODO: Move this out to a common sass file so it can be used in the modal
  .achievements {
    white-space: nowrap;
    overflow-x: auto;
    display: flex;
    align-items: center;
    padding: 2px 0px 10px;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .page-button {
      cursor: pointer;
    }
  }
}
