@import 'styles/variables';

.ui.header:first-child {
  margin-top: $double-space-around;
}

.ui.form {
  .field textarea {
    font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  }
}

.ui.cards > .card.horizontal > :first-child,
.ui.card.horizontal > :first-child {
  border-radius: 0.28571429rem 0em 0em 0.28571429rem !important;
}

.ui.cards > .card > .image,
.ui.card > .image {
  &.ui.placeholder {
    background-color: #ffffff;
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.08) 0%,
      rgba(0, 0, 0, 0.15) 15%,
      rgba(0, 0, 0, 0.08) 30%
    );
    background-size: 1200px 100%;
  }
}

.ui.placeholder {
  max-width: unset;
}

.ui.popup {
  z-index: 10;
}

.ui.message {
  &.error {
    a {
      color: #4183c4;

      &:hover {
        color: #4183c4;
        text-decoration: underline;
      }
    }
  }
}
