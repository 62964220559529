@import 'styles/variables';

.AddVisitForm {
  &.inline {
    position: relative;

    .date-picker {
      .SingleDatePicker {
        width: 90px;

        .SingleDatePickerInput {
          input.DateInput_input__small {
            font-size: 12px !important;
            line-height: 1 !important;
            padding: 0.5833em 0.833em !important;
            font-weight: bold !important;
            height: 26px;
            text-align: center;
            color: #ffffff;
            cursor: pointer;

            &::placeholder {
              color: $accent-color !important;
              text-transform: uppercase;
              text-align: center;
            }
          }
        }
      }

      &.empty {
        .SingleDatePicker .SingleDatePickerInput {
          input.DateInput_input__small {
            border-color: $accent-color !important;
            background-color: #ffffff !important;

            &:focus {
              background-color: #ffffff !important;
            }
          }
        }
      }

      &:not(.empty) {
        .SingleDatePicker .SingleDatePickerInput {
          input.DateInput_input__small {
            border: none !important;
            background-color: $accent-color !important;

            &:focus {
              background-color: $accent-color !important;
            }
          }
        }
      }
    }

    .notes {
      margin-top: $space-around !important;
    }

    button.ui.button {
      float: right;
      margin: 0;
    }
  }
}
