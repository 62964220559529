@import 'styles/variables';

$height-nav-bar: 55px;

.Root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .AppBar {
    flex: 0 0 auto;
    height: $height-nav-bar;
  }

  .Banner {
    background-color: $secondary-color-dark;
    color: $background-color;
    padding: $space-around;
    margin-top: $height-nav-bar;
    text-align: center;
    border-bottom: 1px solid $border-color;
    margin-bottom: -$height-nav-bar;

    display: flex;
    align-items: center;

    .banner-message {
      margin-left: 50px;
      flex: 1 1 auto;

      a {
        color: lighten($primary-color, 5%);

        &:hover {
          text-decoration: underline;
        }
      }
    }

    i.icon.close {
      margin: 0 0 5px;
      flex: 0 0 50px;
      height: 15px;
    }
  }

  &.desktop {
    .root-content {
      margin-top: $height-nav-bar;
      flex: 1 1 auto;
      width: 100%;
      overflow: hidden;
    }
  }

  &.mobile {
    .scrollable {
      margin-top: $height-nav-bar;
      flex: 1 1 auto;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .root-content {
      width: 100%;
      height: 100%;
    }
  }
}
