@import 'styles/variables';

.LoginForm,
.VerifyEmail,
.Register,
.ForgotPassword {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  .primary.button {
    margin: 0;
  }

  .ui.form {
    // TODO: Base this on media query
    width: 290px;
  }

  .forgot-password-link {
    text-align: right;
    font-size: 0.95em;
    margin-top: -1 * $double-space-around !important;
  }
}
