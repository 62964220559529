@import 'styles/variables';

.BothyInfoBar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .save-icon {
    color: $accent-color;
  }

  .ui.label.basic {
    border: none;
    text-transform: uppercase;

    .icon {
      margin-right: 3px;
    }
  }

  &.vertical {
    flex-direction: column;
    align-items: flex-end;

    .primary {
      order: 1;
    }

    .secondary {
      margin-top: $space-around;
      order: 2;
    }
  }
}
