@import 'styles/variables';

.ViewAchievementModal {
  .content {
    text-align: center;

    .achievement-description {
      font-size: 18px;
    }

    .achievement-date-unlocked {
      color: $text-secondary-color;
    }
  }
}
