.AddVisitModal {
  .ui.form {
    display: flex;
    flex-direction: column;
    height: 100%;

    .notes {
      flex-grow: 1;
  
      textarea {
        resize: none;
        height: calc(100% - 22px);
      }
    }
  }
}