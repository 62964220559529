@import 'styles/variables';

.VisitsTab {
  margin: $double-space-around;

  .add-visit {
    margin: $double-space-around 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $accent-color;
    text-transform: uppercase;

    i.icon {
      margin-bottom: $space-around;
    }
  }
}
