@import 'variables';

// NOTE: the order of these styles DO matter

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
  background: $accent-color;
  border-color: $accent-color;
}

// Will edit when hovered over. _span style also has this property
.CalendarDay__selected:hover {
  background: $accent-color;
}

.CalendarDay__default:hover {
  background: rgba($brown, 0.75);
}
