@import 'styles/variables';

.DiscoverPage {
  .SearchFilterBar {
    flex: 0 0 auto;
    margin: $double-space-around $double-space-around 0px;
  }

  .BothyList {
    margin: $double-space-around;
  }
}
