@import 'styles/variables';

.TripsPage {
  height: 100%;
  display: flex;
  flex-direction: column;

  .tab-menu {
    .item.active {
      color: $primary-color !important;
      border-color: $primary-color !important;
    }
  }
}
