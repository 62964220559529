@import 'styles/variables.scss';

.Sidebar {
  > .sidebar {
    width: 65% !important;

    .header {
      text-align: center;
      padding-bottom: 0;
    }

    .item.active {
      color: $primary-color !important;
    }
  }

  &.pushable .pusher {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
