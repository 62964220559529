@import 'styles/variables';

.MapPage {
  position: relative;

  .BothyFilterPanel {
    z-index: 5;
    position: absolute;
    margin: $space-around;
  }
}
