@import 'styles/variables';

.Achievement {
  display: inline-block;
  margin-right: 22px;

  &:first-child {
    margin-left: $double-space-around;
  }

  &:last-child {
    margin-right: $double-space-around;
  }

  p {
    text-align: center;
    margin-top: $double-space-around;
  }

  .text {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-family: inherit;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
  }
}

.LockedAchievement {
  i.icon {
    color: #a2a2a2;
    opacity: 0.3;
  }

  p {
    text-transform: uppercase;
    opacity: 0.3;
  }
}

.UnlockedAchievement {
  i.icon {
    &.certificate {
      color: $primary-color;
    }

    color: $accent-color;
  }
}
