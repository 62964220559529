@import 'styles/variables';

.VisitDiary {
  .VisitDiaryEntry {
    margin: $double-space-around;

    .header i.icon {
      margin-left: $space-around;
    }

    p {
      margin-top: $space-around;
      white-space: pre-wrap;
      word-break: break-word;
    }
  }
}
