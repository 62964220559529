.ui.bottom.attached.segment.tab {
  border: 0;
}

.ui.pointing.secondary.menu.tab-menu {
  margin-bottom: 0;
}

.ui.segment.tab {
  padding: 0;
}
