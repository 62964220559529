@import 'styles/variables';

.BothyFilterPanel {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ui.label.button-filter {
    margin-right: $double-space-around;
    font-weight: initial;
    cursor: pointer;
  }

  .clear-filters {
    color: $accent-color;
    font-size: 1em;
    text-decoration: underline;
    text-transform: uppercase;
    cursor: pointer;
  }
}
