.root-content .mobile-page {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.root-content .desktop-page {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: row;
}
