@import 'styles/variables';
@import 'styles/mixins';

.AppBar {
  .app-name {
    margin-left: 12px;
    display: none;
  }

  &.ui.menu {
    .item {
      padding: 0.9rem;
    }
  }

  .user-section {
    margin-left: auto;
    display: flex;

    > i.icon {
      width: initial;

      .menu {
        font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
        font-size: 18px;

        .header {
          text-transform: initial;

          .icon {
            margin-right: $space-around;
          }
        }

        .divider {
          margin-bottom: 0;
        }
      }
    }
  }

  @include above(small) {
    &.ui.menu {
      .hamburger-item {
        display: none !important;
      }
    }

    .app-name {
      display: initial;
    }
  }
}

.achievement-popup {
  width: 280px;
  min-height: 100px;
}
